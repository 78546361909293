.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.DalemanDiv {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ---- */
.WorkoutonomyDiv {
  background-color: #111827;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.gradient-text {
  @apply bg-clip-text text-transparent;
  @apply bg-gradient-to-br from-indigo-500 via-fuchsia-500 to-pink-500;
}
.workoutonomyGradient {
  /* font-size: 72px; */
  /* background: -linear-gradient(to bottom, #6366f1, #7c63f3, #935ef5, #a959f4, #be52f3, #cd4cea, #da45e1, #e53fd7, #eb3dc6, #ed3fb5, #ee43a7, #ec4899); */
  background-image: linear-gradient(to bottom, #6366f1, #7c63f3, #935ef5, #a959f4, #be52f3, #cd4cea, #da45e1, #e53fd7, #eb3dc6, #ed3fb5, #ee43a7, #ec4899);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.workoutonomyFlamingo{
  color:#f472b6;
}